import { Notification, Message } from 'element-ui'
import { fileUpload } from "@/api";

/**
 * @description 不满10的数字补0
 * @param {string|number} num
 */
const addZero = num => {
  if(isNaN(num)) return NaN
  return String(num).padStart(2, '0')
}

/**
 * @typedef {Object} elOptionsItem
 * @prop {string} label
 * @prop {*} value
 * @prop {elOptionsItem[]} [children]
 */
/**
 * @type {elOptionsItem}
 */

/**
 * @description ele 下拉选项数据转换
 * @param {object} config               传入的参数
 * @param {array} config.list           转化的数据
 * @param {string} config.label         显示的文字
 * @param {string} config.value         数据的value值
 * @param {boolean} [config.all]        [false|true] false 只返回转化数据  true 返回所有数据
 * @returns {elOptionsItem[]}
 */
export const changeOption = (config) => {
  const { list = [], label = 'label', value = 'value', children = 'children', all = false } = config
  return list.map(item => {
    const { [label]: itemLabel, [value]: itemValue, [children]: itemChildren } = item
    const itemData = all ? { ...item, label: itemLabel, value: itemValue } : { label: itemLabel, value: itemValue }
    if(itemChildren) {
      itemData.children = changeOption({ list: itemChildren, label, value, children })
    }
    return itemData
  })
}

/**
 * @description 表单下拉数据处理
 * @param {object} config               传入的参数
 * @param {array} config.key            处理的key
 * @param {array} config.list           待处理的配置列表数据
 * @param {array} config.data           请求接口获取的数据
 * @param {string=} config.label        显示的文字
 * @param {string=} config.value        数据的value值
 * @param {boolean=} [config.all]       [false|true] false 只返回转化数据  true 返回所有数据
 * @returns {void}
 */
export const setConfigOption = (config) => {
  const { key, list = [], data = [], ...other } = config
  let arr = [...data]
  const idx = list.findIndex(item => item.prop === key)
  if(JSON.stringify(other) !== '{}') {
    arr = changeOption({ ...other, list: data })
  }
  if (~idx) {
    list[idx].options = arr
  } else {
    list.forEach(item => {
      if(item.children) {
        setConfigOption({ key, list: item.children, data, ...other })
      }
    })
  }
}

/**
 * @description 表单默认值设置处理
 * @param {object} config               传入的参数
 * @param {array} config.key            处理的key
 * @param {array} config.list           待处理的配置列表数据
 * @param {string=} config.changeKey    需要改变值的key
 * @param {string=} config.value        需要改变的值
 * @returns {void}
 */
export const setConfigValue = (config) => {
  let { key, list = [], changeKey = 'value', value = ''} = config
  if(typeof key === 'string') key = [key]
  key.forEach(item => {
    const idx = list.findIndex(i => i.prop === item)
    if (~idx) {
      list[idx][changeKey] = value
    } else {
      list.forEach(c => {
        if(c.children) {
          setConfigValue({ key, list: c.children, value, changeKey })
        }
      })
    }
  })
}

/**
 * @description 获取数组中某个key的指定值
 * @param {object} config               传入的参数
 * @param {array} config.key            处理的key
 * @param {array} config.list           待处理的配置列表数据
 * @param {string} config.valueKey    需要获取值的key
 * @returns {any}
 */
export const getConfigValue = (config) => {
  let { key, list = [], valueKey = 'value'} = config
  if(typeof key !== 'string') {
    Message.warning('key的类型必须是字符串')
    return ''
  }
  const obj = list.find(i => i.prop === key)
  return obj ? obj[valueKey] : ''
}

/**
 * @description 处理编辑数据时去掉自定义id，避免保存不成功
 * @param {object[]} list 带出来数据
 * @returns {object[]}
 */
export const transformAddOrEditData = (list = []) => {
  return list.map(item => {
    const { id, isAdd, ...other } = item
    if(isAdd) return { ...other }
    return { ...other, id }
  })
}

/**
 * @description JS转成ex文件，前端进行导出
 * @param {object} config               传入的参数
 * @param {*[]} config.data             JSON数据
 * @param {string} [config.name]        你希望导出文件的文件名
 * @param {string} [config.type]        你希望导出文件的文件格式
 * @param {string} [config.titleKey]    自定义的 表格对应字段
 * @param {string} [config.titleName]   自定义的 表格标题栏文字
 * @param {*[]=} config.title           你希望的字段名也就是表头
 * @param {*[]=} config.filter          你希望过滤的 key
 * @param {boolean} [config.tips]       是否显示提示信息
 */
export const JSONToExcel = (config = {}) => {
  const { data, name, type = 'csv', titleKey = 'value', titleName = 'label', title = [], filter = [], tips = false } = config
  if (!data) return;
  //转化json为object
  const arrData = typeof data !== 'object' ? JSON.parse(data) : data;

  let str = '', titleIsObject = '', keyArr = []
  const titleLen = title.length;
  //设置表头
  const isHasTitle = titleLen ? true : false;
  if(isHasTitle) {
    titleIsObject = typeof title[0] === 'object'
    if(titleIsObject) {
      keyArr = title.map(item => item[titleKey])
    }
    const arr = titleIsObject ? title.map(item => item[titleName]) : title;
    //列标题，逗号隔开，每一个逗号就是隔开一个单元格
    str += `${arr.join(',')}\n`;
  }
  //增加\t为了不让表格显示科学计数法或者其他格式
  const isFilter = filter.length ? true : false;
  for(let i = 0 ; i < arrData.length ; i++ ){
    const arr = []
    if(titleIsObject) {
      for (const key of keyArr) {
        arr.push(`${arrData[i][key]}\t`)
      }
      str += `${arr.join(',')}\n`
      continue
    }
    if(isFilter) {
      for(let key of filter){
        arr.push(`${arrData[i][key]}\t`)
      }
      str += `${arr.join(',')}\n`
      continue
    }
    for(let key in arrData[i]){
      if(isHasTitle && arr.length >= titleLen) {
        break
      }
      arr.push(`${arrData[i][key]}\t`)
    }
    str += `${arr.join(',')}\n`
  }
  tips && Notification({
    type: 'info',
    title: '温馨提示',
    message: '如果数据庞大会导致下载缓慢哦，请您耐心等待！',
    offset: 100
  })
  // encodeURIComponent解决中文乱码
  let url = `data:text/csv;charset=utf-8,\ufeff${encodeURIComponent(str)}`;
  let link = document.createElement("a");
  link.href = url;
  link.download = `${name || Date().now()}.${type}`;
  link.click();
  link = null
}

/**
 * @description 日期重置时间为 00:00:00 并返回时间戳
 * @param {number} time 目标日期
 * @returns {number}
 */
const getStartTime = time => {
  const nowTimeDate = new Date(time)
  return nowTimeDate.setHours(0, 0, 0, 0)
}

/**
 * @description 日期格式化
 * @param {string|number|Date} [dateVal] 目标日期 默认当前时间
 * @param {object} [config] 参数
 * @param {boolean} [config.fill] 不满10的数字是否补0
 * @param {string} [config.separator] 输出日期之间的分隔符，默认'-'
 * @param {string} [config.splitSeparator] 传入日期字符串时，日期与时间之间的分隔符，分隔符 默认' '
 * @param {string} [config.dateSeparator] 传入日期字符串时，日期之间的分隔符，分隔符 默认支持[.|-| _| |:]
 * @param {string} [config.timeSeparator] 传入日期字符串时，时间之间的分隔符，分隔符 默认':'
 * @returns object
 */
export const formatDate = (dateVal, config = {}) => {
  const { separator = '-', fill = true, splitSeparator = ' ', dateSeparator = '', timeSeparator = '' } = config
  let val
  if (typeof dateVal === 'string') {
    const valArr = dateVal.split(splitSeparator)
    let newVal1 = valArr[0]
    let newVal2 = valArr[1] || ''
    const regArr = ['\\.', '-', '_', ' ', ':']
    if(dateSeparator) {
      regArr.push(dateSeparator)
    }
    if(timeSeparator) {
      newVal2 = newVal2.replace(new RegExp(timeSeparator, 'g'), ':')
    }
    regArr.forEach(i => {
      newVal1 = newVal1.replace(new RegExp(i, 'g'), '/')
    })
    val = `${newVal1}${newVal2 ? ` ${newVal2}` : ''}`
  } else if (typeof dateVal === 'number') {
    const len = (dateVal + '').length
    if (len === 13) {
      val = dateVal
    } else if (len === 10) {
      val = dateVal * 1000
    }
  } else if (dateVal instanceof Date) {
    val = dateVal
  }
  const curDate = val ? new Date(val) : new Date()
  const [year, month, day, hour, minute, second, timeStamp, currentTimeStamp, week] = [
    curDate.getFullYear(),
    curDate.getMonth() + 1,
    curDate.getDate(),
    curDate.getHours(),
    curDate.getMinutes(),
    curDate.getSeconds(),
    getStartTime(curDate.getTime()),
    curDate.getTime(),
    curDate.getDay()
  ]
  let newMonth = month,
    newDay = day,
    newHour = hour,
    newMinute = minute,
    newSecond = second
  if(fill) {
    newMonth = addZero(month)
    newDay = addZero(day)
    newHour = addZero(hour)
    newMinute = addZero(minute)
    newSecond = addZero(second)
  }
  let date = `${year}${separator}${newMonth}${separator}${newDay}`
  let time = `${newHour}:${newMinute}`
  let fullTime = `${time}:${newSecond}`
  let dateTime = `${date} ${time}`
  let fullDateTime = `${date} ${fullTime}`
  if (isNaN(year)) {
    date = NaN
    time = NaN
    fullTime = NaN
    dateTime = NaN
    fullDateTime = NaN
  }
  return { year, month, day, hour, minute, second, week, date, time, dateTime, fullTime, fullDateTime, timeStamp, currentTimeStamp }
}

/**
 * @description 获取指定日期距离指定天数的日期
 * @param {Number} day 距离指定日期的天数
 * @param {String} val 目标日期 默认当前时间
 * @returns String
 */
export const getDayFromNow = (day = 0, val) => {
  const today = val ? new Date(val) : new Date()
  const targetDay = today.getTime() + 1000 * 60 * 60 * 24 * day
  today.setTime(targetDay) // 注意，这行是关键代码
  const [tYear, tMonth, tDate] = [today.getFullYear(), today.getMonth() + 1, today.getDate()]
  return tYear + '-' + addZero(tMonth) + '-' + addZero(tDate)
}

/**
 * @description 获取指定日期当前周的指定天数的日期
 * @param {string} value 目标日期 默认当前时间
 * @param {number} start 指定当前周的第几天
 * @returns String
 */
export const getWeekStartDate = (val = '', start = 1) => {
  const { week, timeStamp } = formatDate(val)
  const index = start - (week ? week : 7)
  const res = getDayFromNow(index, timeStamp)
  return res
}

export const setDatePicker = ({ num = 0, date = '', type = 'day', excludeToday = 0, yearType } = {}) => {
  const { date: newDate } = formatDate(date)
  let nowDate = new Date(newDate)
  if (excludeToday) {
    nowDate.setTime(nowDate.getTime() - 3600 * 1000 * 24 * 1)
  }
  if (type === 'day') {
    nowDate.setTime(nowDate.getTime() - 3600 * 1000 * 24 * num)
  } else if (type === 'month') {
    const [year, month, day] = [nowDate.getFullYear(), nowDate.getMonth() + 1, nowDate.getDate()]
    const symbol = num >= 0
    const subtractYear = Math.floor(Math.abs(num) / 12)
    const subtractMonth = num % 12
    let newYear = symbol ? year - subtractYear : year + subtractYear
    let newMonth = month - subtractMonth
    if (subtractMonth >= month) {
      newMonth = 12 - subtractMonth + month
      newYear -= 1
    }
    nowDate = new Date(`${newYear}-${newMonth}-${day}`)
  } else if (type === 'year') {
    let [year, month, day] = [nowDate.getFullYear(), nowDate.getMonth() + 1, nowDate.getDate()]
    if(yearType === 'start') {
      month = 1
      day = 1
    } else if(yearType === 'end') {
      month = 12
      day = 31
    }
    nowDate = new Date(`${year - num}-${month}-${day}`)
  }
  return nowDate
}

/**
 * @description 隐藏身份证中间内容
 * @param {string} idCard 身份证号
 * @param {object} [config] 配置项
 * @param {number} config.start 从第start个字符开始隐藏
 * @param {number} config.end end个字符后结束隐藏
 * @returns string
 */
export const hideIdCard = (idCard, config = {}) => {
  if(!idCard) return ''
  const { start = 6, end = 4 } = config
  const len = idCard.length, num = len - start;
  const startStr = idCard.substring(0, start)
  const endStr = idCard.substring(len - end, len)
  const newEnd = endStr.padStart(num, '*')
  return startStr + newEnd
}

/**
 * @description 返回数据的类型
 * @param {*} data 传入的数据
 * @returns {string}
 */
export const typeOfData = (data) => Object.prototype.toString.call(data).slice(8, -1);

/**
 * @description 对某个对象的某个属性做一些处理
 * @param {object} obj 处理的对象
 * @param {string} key 对象的key值
 * @returns {unknown}
 */
const handlerObject = (obj, key) => obj[key]

/**
 * @description 判断某个值是否为空
 * @param {unknown} value 判断的值
 * @param {*[]} list 自定义白名单的值
 * @returns {boolean}
 */
const valueIsEmpty = (value, list = []) => {
  let whiteArr = ['', null]
  whiteArr = whiteArr.concat(list)
  if (whiteArr.includes(value)) return true
  if (['{}', '[]'].includes(JSON.stringify(value))) return true
  return false
}

/**
 * @description 判断对象是否全部有值，多用于表单页面提交判断
 * @param {object} obj 处理的对象
 * @param {string[]} [list] 自定义白名单的key
 * @param {*[]} [list2] 自定义白名单的value
 * @returns {boolean}
 */
export const objectIsFull = (obj, list = [], list2 = []) => {
  let objIsFull = true
  Object.keys(obj).forEach(key => {
    if (list.includes(key)) return
    const value = handlerObject(obj, key)
    const isEmpty = valueIsEmpty(value, list2)
    if (isEmpty) objIsFull = false
  })
  return objIsFull
}

/**
 * @description 判断对象是否全部值为空，多用于表单页面返回判断
 * @param {object} obj 处理的对象
 * @param {string[]} list 自定义白名单的key
 * @param {*[]} list2 自定义白名单的value
 * @returns {boolean}
 */
export const objectIsEmpty = (obj, list = [], list2 = []) => {
  let objValueEmpty = true
  Object.keys(obj).forEach(key => {
    if (list.includes(key)) return
    const value = handlerObject(obj, key)
    const isEmpty = valueIsEmpty(value, list2)
    if (!isEmpty) {
      objValueEmpty = false
    }
  })
  return objValueEmpty
}

/**
 * @description 返回对象空值的key的集合
 * @param {object} obj 处理的对象
 * @param {*[]} list 自定义白名单的value
 * @returns {string[]}
 */
export const reBackEmpty = (obj, list = []) => {
  let arr = []
  Object.keys(obj).forEach(key => {
    const value = handlerObject(obj, key)
    const isEmpty = valueIsEmpty(value, list)
    if (isEmpty) {
      arr.push(key)
    }
  })
  return arr
}

/**
 * @description 页面中点击按钮上传文件
 * @param {function} func 上传文件方法
 * @param {object} config 配置参数
 * @param {string} config.accept 可选文件类型
 * @param {object} config.data 上传参数
 */
export const handleChooseFile = (config = {}) => {
  const { accept = '.xls,.xlsx,.xlsm,.csv', data = {}, key ='file', api, show = false, onBeforeUpload, onSuccess, onError, onFinish } = config
  if(!api) return Message.warning('请传入上传地址')
  let ipt = document.createElement('input')
  ipt.type = 'file'
  ipt.accept = accept
  ipt.click()
  ipt.addEventListener('change',(e)=>{
    let formData = new FormData()
    formData.append(key, e.target.files[0])
    Object.keys(data).forEach(key => {
      formData.append(key, data[key])
    })
    onBeforeUpload && onBeforeUpload()
    fileUpload(api, formData, { show }).then(res => {
      onSuccess && onSuccess(res)
    }).catch(err => {
      onError && onError(err)
    }).finally(() => {
      onFinish && onFinish()
    })
    ipt = null
  })
}

/**
 * @description 复制文本到剪贴板
 * @param {string} text 复制的文本
 */
export const copyText = (text) => {
  if (navigator.clipboard) return navigator.clipboard.writeText(text).then(() => {
    Message.success('复制成功')
  }).catch(() => {
    Message.success('复制失败')
  })
  let ipt = document.createElement('input')
  ipt.value = text
  document.body.appendChild(ipt)
  ipt.select()
  document.execCommand('copy')
  ipt.remove()
  Message.success('复制成功')
}
